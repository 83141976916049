import { Component, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { fadeInLeft, zoomIn, fadeIn } from "@core/animations/core.animation";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "content",
  templateUrl: "./content.component.html",
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInLeft, zoomIn, fadeIn],
})
export class ContentComponent {
  public animate = "fadeIn";

  // Private
  private _unsubscribeAll: Subject<void>;

  /**
   * Constructor
   *
   *
   */
  constructor() {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  /**
   * Fade In Left Animation
   *
   * @param outlet
   */
  fadeInLeft(outlet: RouterOutlet) {
    if (this.animate === "fadeInLeft") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Zoom In Animation
   *
   * @param outlet
   */
  zoomIn(outlet: RouterOutlet) {
    if (this.animate === "zoomIn") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  /**
   * Fade In Animation
   *
   * @param outlet
   */
  fadeIn(outlet: RouterOutlet) {
    if (this.animate === "fadeIn") {
      return outlet.activatedRouteData.animation;
    }
    return null;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {}
}
