import { Component, OnInit, Input, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: boolean;
  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}

@Component({
  selector: "app-content-header",
  templateUrl: "./content-header.component.html",
  styleUrls: ["./content-header.component.scss"],
})
export class ContentHeaderComponent implements OnInit {
  // input variable
  @Input() contentHeader: ContentHeader | undefined;

  public currentUser: User | undefined;

  // Private
  private _unsubscribeAll: Subject<void>;

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    public _translateService: TranslateService,
    private _coreSidebarService: CoreSidebarService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    // get the currentUser details from localStorage
    let user: string;
    user = localStorage.getItem("currentUser") ?? "";
    if (user === "") {
      this.currentUser = undefined;
    } else {
      this.currentUser = JSON.parse(user);
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(["/pages/authentication/login-v2"]);
  }

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key: string): void {
    const sidebarRegistry = this._coreSidebarService.getSidebarRegistry(key);
    if (!sidebarRegistry) return;
    sidebarRegistry.toggleOpen();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
