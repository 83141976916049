import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { hmrBootstrap } from "./hmr";

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  console.error(
    "fix module['hot']. Hot is not a property of module. Probably deprecated stuff to fix when needed"
  );
  // if (module["hot"]) {
  //   hmrBootstrap(module, bootstrap);
  // } else {
  //   console.error("HMR is not enabled for webpack-dev-server!");
  //   console.log("Are you using the --hmr flag for ng serve?");
  // }
} else {
  bootstrap().catch((err) => console.log(err));
}
