<!-- app-content-header start -->
<ng-container *ngIf="contentHeader">
  <div class="content-header row">
    <div class="content-header-left col-md-9 col-11 mb-2">
      <div class="row breadcrumbs-top">
        <div class="col-12 d-flex">
          <ul class="nav navbar-nav d-xl-none mr-1">
            <li class="nav-item">
              <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                <i class="feather icon-menu font-medium-5"></i>
              </a>
            </li>
          </ul>
          <ul class="nav navbar-nav d-xl mr-2">
            <li class="nav-item">
              <a class="nav-link">
                <div class="position-relative d-inline-block">
                  <span
                    [data-feather]="'bell'"
                    [class]="'font-medium-5 text-primary'"
                  ></span>
                  <span class="badge badge-pill badge-primary badge-up">4</span>
                </div>
              </a>
            </li>
          </ul>
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb
            [breadcrumb]="contentHeader.breadcrumb"
          ></app-breadcrumb>
        </div>
      </div>
    </div>
    <ng-container *ngIf="contentHeader.actionButton">
      <div class="content-header-right text-md-right col-md-3 col-1 d-md-block">
        <div class="form-group breadcrum-right">
          <a class="nav-link menu-toggle" (click)="logout()">
            <i class="feather icon-log-out font-medium-5 text-danger"></i>
          </a>

          <!-- Use this if in the future we want multiple action buttons on every page -->
          <!-- <div ngbDropdown>
            <button
              ngbDropdownToggle
              id="dropdownSettings"
              class="btn-icon btn btn-primary btn-round btn-sm"
              type="button"
              rippleEffect
            >
              <span [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a ngbDropdownItem [routerLink]="['/']"
                ><span [data-feather]="'log-out'" [class]="'mr-50'"></span>
                Logout</a
              >
            </div>
          </div> -->
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<!-- app-content-header end -->
