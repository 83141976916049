import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { CustomToastrComponent } from './custom-toastr/custom-toastr.component';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private options: GlobalConfig;

  constructor(private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  // Success
  success(message: string, title: string) {
    this.toastr.success(message, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      progressBar: true,
    });
  }

  // Warning
  warning(message: string, title: string) {
    this.toastr.warning(message, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      progressBar: true,
    });
  }

  // Error
  error(message: string, title: string) {
    this.toastr.error(message, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      progressBar: true,
    });
  }

  info(message: string, title: string) {
    this.toastr.error(message, title, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      progressBar: true,
    });
  }
}
