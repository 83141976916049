import { NgModule } from "@angular/core";

import { VerticalLayoutModule } from "app/layout/vertical/vertical-layout.module";

@NgModule({
  imports: [VerticalLayoutModule],
  providers: [],
  exports: [VerticalLayoutModule],
})
export class LayoutModule {}
