import { Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";

import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CoreMediaService {
  currentMediaQuery: string;
  onMediaUpdate: BehaviorSubject<string> = new BehaviorSubject<string>("");

  /**
   * Constructor
   *
   * @param {MediaObserver} _mediaObserver
   */
  constructor(private breakpointObserver: BreakpointObserver) {
    // Set the defaults
    this.currentMediaQuery = "";

    // Initialize
    this._init();
  }

  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    this.breakpointObserver
      .observe([
        "(max-width: 575.98px)", //bs-xs
        "(min-width: 576px) and (max-width: 767.98px)", //bs-sm
        "(min-width: 768px) and (max-width: 991.98px)", //bs-md
        "(min-width: 992px) and (max-width: 1199.98px)", //bs-lg
        "(min-width: 1200px)", //bs-xl
        "(min-width: 576px)", //bs-gt-sm
        "(min-width: 768px)", //bs-gt-md
        "(min-width: 992px)", //bs-gt-lg
        "(min-width: 1200px)", //bs-gt-xl
        "(max-width: 575.98px)", //bs-lt-sm
        "(max-width: 767.98px)", //bs-lt-md
        "(max-width: 991.98px)", //bs-lt-lg
        "(max-width: 1199.98px)", //bs-lt-xl
      ])
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((result) => {
        let mqAlias = "";
        if (result.breakpoints["(max-width: 575.98px)"]) {
          mqAlias = "bs-xs";
        } else if (
          result.breakpoints["(min-width: 576px) and (max-width: 767.98px)"]
        ) {
          mqAlias = "bs-sm";
        } else if (
          result.breakpoints["(min-width: 768px) and (max-width: 991.98px)"]
        ) {
          mqAlias = "bs-md";
        } else if (
          result.breakpoints["(min-width: 992px) and (max-width: 1199.98px)"]
        ) {
          mqAlias = "bs-lg";
        } else if (result.breakpoints["(min-width: 1200px)"]) {
          mqAlias = "bs-xl";
        } else if (result.breakpoints["(min-width: 576px)"]) {
          mqAlias = "bs-gt-sm";
        } else if (result.breakpoints["(min-width: 768px)"]) {
          mqAlias = "bs-gt-md";
        } else if (result.breakpoints["(min-width: 992px)"]) {
          mqAlias = "bs-gt-lg";
        } else if (result.breakpoints["(min-width: 1200px)"]) {
          mqAlias = "bs-gt-xl";
        } else if (result.breakpoints["(max-width: 575.98px)"]) {
          mqAlias = "bs-lt-sm";
        } else if (result.breakpoints["(max-width: 767.98px)"]) {
          mqAlias = "bs-lt-md";
        } else if (result.breakpoints["(max-width: 991.98px)"]) {
          mqAlias = "bs-lt-lg";
        } else if (result.breakpoints["(max-width: 1199.98px)"]) {
          mqAlias = "bs-lt-xl";
        }

        if (this.currentMediaQuery !== mqAlias) {
          this.currentMediaQuery = mqAlias;
          this.onMediaUpdate.next(mqAlias);
        }
      });
  }
}
